/*
 * @Author: hwu
 * @Date: 2021-09-29 09:50:04
 * @Description: file content
 * @LastEditTime: 2024-05-27 11:57:23
 */
import Vue from 'vue'
import { Popup, Image, FootBtn } from 'w-ui'
Vue.use(Popup)
Vue.use(Image)
Vue.use(FootBtn)
